.calendar-table {
    width:100%;
    border-right:1px solid #EEE;
    border-top:1px solid #EEE;
}
.calendar-table tr {
    border-bottom:1px solid #EEE;
}
.calendar-table th, .calendar-table td {
    border-left:1px solid #EEE;
    padding:10px;
}
.calendar-table__header {

}
.calendar-table__header__task-column {
    text-align:right;
}
.calendar-table__header__date {

}
.calendar-table__header__date--highlight {
    border-color:#83A9E6 !important;
    border-width: 3px !important;
    border-top:3px solid #83A9E6 !important;
    color:#0052CC;
    border-bottom:0 !important;
}
.calendar-table__header__date--special {
    background:#E1E1E1;
}
.calendar-table__header__date--highlight + th {
    border-color:#83A9E6 !important;
    border-width: 3px !important;
    border-bottom:0 !important;
}
.calendar-table__header__highlight {
    position:absolute;
    margin-top:-10px;
    background:#0052CC;
    color:#FFF;
    text-align:center;
    display:block;
    display:none;
}
.calendar-table__header__date__day {
    font-size:1.5em;
    font-weight:bold;
    display:block;
}
.calendar-table__header__date__week {
    font-size:0.9em;
    font-weight:normal;
}
.calendar-table__header__date__month {
    font-size:0.7em;
    font-weight:500;
    color:#AAA;
}
.calendar-table__body {

}
.calendar-table__body__task-column {
    width:24%;
}
.calendar-table__body__task-column__title {
    font-size:0.9em;
    font-weight:bold;
    display:block;
}
.calendar-table__body__task-column__description {
    font-size: 0.9em;
    font-weight: 400;
    display: block;
}
.calendar-table__body__task-column__avatar {
    border-radius:50%;
    float:left;
    margin-right:10px;
    width:35px;
    height:35px;
    border:1px solid #CCC;
}
.calendar-table__body__estimate-column {
    color:#0052CC;
    font-weight:500;
}
.calendar-table__body__total-column {
    color:#333;
}
.calendar-table__body__total-before-column {
    color:#333;
}
.calendar-table__body__day-column {
    color:#333;
}
.calendar-table__body__day-column:empty {
    background:#F5F5F5;
}
.calendar-table__body__day-column--highlight, .calendar-table__body__day-column--highlight + td, .calendar-table__body__day-column-total--highlight, .calendar-table__body__day-column-total--highlight + td {
    border-color:#83A9E6 !important;
    border-width: 3px !important;
}
.calendar-table__body__day-column--highlight + td, .calendar-table__body__day-column--highlight, .calendar-table__body__day-column-total--highlight + td {
    border-bottom: 0 !important;
}
.calendar-table__body__day-column-total--highlight {
    border-bottom:3px solid #83A9E6;
    background:#0052CC;
    color:#FFF;
}
.calendar-table .calendar-table__body__day-column--special {
    background: #E1E1E1;
}
.calendar-table .calendar-table__body__day-column-total--special {
    background: #E1E1E1 !important;
}
.zero-column {
    color:#333;
}
.btn-calendar-left:hover, .btn-calendar-right:hover, .btn-calendar-left:hover, .btn-calendar-right:active {
    background:#d0231f;
    color:#FFF;
    border-color:#A94442;
}
.btn-calendar-right, .btn-calendar-left {
    color: #545454;
    background-color: #F5F5F5;
    border-color:#ddd;
    display:block;
    margin:0 auto;
    margin-top:2px;
    margin-bottom:2px;
    margin-right:0;
}
a {
    color:#0052CC;
    text-decoration:none;
}
.ranges li.active, .ranges li:hover {
    background-color: #CC1218 !important;
    border: 1px solid #C50E14 !important;
    color: #FFF !important;
}
.ranges li {
    color: #0052CC !important;
}

.page-title {
    font-size:1.2em;
    margin-bottom:10px;
}
.panel-danger-custom {
    border-color:#ff0100;
    padding-bottom:1px;
}
.panel-danger-custom>.panel-heading {
    background-color:#f5f5f5;
    border-color: #ddd;
    color:#333;
}
.panel-danger-custom .fa-exclamation-triangle:before, .panel-danger-custom .fa-warning:before {
    color:#ff0000;
}
.applyBtn {
    color: #FFF;
    background-color: #008000;
    border-color: #008000;
}
.applyBtn.active, .applyBtn.focus, .applyBtn:active, .applyBtn:focus, .applyBtn:hover, .open>.dropdown-toggle.applyBtn {
    color: #FFF;
    background-color: #27CE27;
    border-color: #34B034;
}
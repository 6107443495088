body {
    overflow-x:hidden;
}
.arrow {
    border: solid #333;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
}
.arrow--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
  
.arrow--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrow--big {
    padding: 13px;
}
.arrow--strong {
    border-width: 0 7px 7px 0;
}
#heatmap-calendar-container .arrow {
    margin-top:70px;
}
.externalTool {
    height:120px;
    line-height: 120px;
    vertical-align: unset;
    display:inline-block;
    list-style:none;
    background:#FFF;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    border-radius: 8px;
    padding: 0 20px 0 20px;
    margin-bottom:20px;
    margin-right:0;
    margin-left:0;
    max-width:340px;
}
.externalTool img {
    width:100%;
}
.select2-container--default .select2-selection--multiple {
    width: 100% !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    height:auto;
}
@media (max-width: 768px) {
    #heatmap-calendar-container .arrow--right {
        margin-left:-20px;
    }
    .select2-container--default .select2-selection--multiple {
        width: auto;
    }
    .search-bar .form-group:last-child, .search-bar .form-group:first-child {
        margin-bottom:15px;
    }
    .search-bar .form-group {
        margin-bottom:0;
    }
    .dataTables_wrapper {
        padding-top:0;
    }
    .hide-phone {
        display:none !important;
    }
}
#top_right_buttons {
    margin-top:10px;
}
#page-wrapper {
    overflow-x:hidden;
}
.text-up {
    color:#19C319;
}
.text-down {
    color:#E40101;
}
.no-margin {
    margin:0 !important;
}
.btn-clean-select2 {
    position:absolute;
    top:7px;
    right:23px;
    background: #F9F9F9;
    color:#3C3C3C;
    padding:1px 12px;
    border-color:#CCC;
}
.btn-clean-select2:hover {
    background: #cc1317;
    color:#FFF;
    border-color:#cc1317;
}
.task_status_container .select2-selection__choice[title="Erledigt"], .task_status_container .select2-selection__choice[title="Done"] {
    background-color: green !important;
    color:#FFF;
    border-color: green !important;
}
.task_status_container .select2-selection__choice .select2-selection__choice__remove {
    color:#FFF !important;
}
.task_status_container .select2-selection__choice[title="In Arbeit"], .task_status_container .select2-selection__choice[title="In Progress"] {
    background-color: #0052CC !important;
    color:#FFF;
    border-color:#0052CC !important;
}
.task_status_container .select2-selection__choice[title="Zu erledigen"], .task_status_container .select2-selection__choice[title="To Do"] {
    background-color: #DFE1E6 !important;
    color: #172B4D;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    float:right;
    margin-right:0;
    margin-left:2px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #ccc !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.select2-container--default .select2-selection--multiple {
    border: 1px solid #CCC !important;
}
.project_avatar {
    width:30px;
    height:30px;
    background-size: contain;
    background-position: center center;
    border-radius:50%;
    border:1px solid #CCC;
    display:inline-block;
    vertical-align: middle;
}
.project_avatar_name {
    display:inline-block;
    vertical-align: middle;
}
.table_project_avatar {

}
.nowrap {
    white-space:nowrap;
}
.developer_avatar {
    width:30px;
    height:30px;
    background-size: contain;
    background-position: center center;
    border-radius:50%;
    border:1px solid #CCC;
    display:inline-block;
    vertical-align: middle;
}
.developer_avatar_name {
    display:inline-block;
    vertical-align: middle;
    white-space: normal;
    width:80%;
}
.panel-loading {
    width:100%;
    height:100%;
    position:absolute;
    background:url(../images/loading.png) no-repeat center center;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:9;
}
.datatable__container {
    position:relative;
    clear:both;
}
.datatable__container__loading {
  position:absolute;
  background:url(../images/loading.png) no-repeat center center;
  background-color:rgba(255, 255, 255, 0.9);
  z-index:3;
  height:100%;
  top:0;
  width:100%;
  text-align:center;
  padding-top:70px;
}
.big-select2 .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-right:50px;
}
.bg-blue {
    background:#0052CC;
    color:#FFF;
    padding:10px;
    border-radius:5px;
}
.dropdown-menu-right {
    left: auto !important;
    right: 0 !important;
}
.dropdown-menu-left {
    left: 0 !important;
    right: auto !important;
}
.panel-default > .panel-heading {
    color: #333333;
    background: linear-gradient(175deg,#ececec 0,#d2d2d2 100%);
    border-color: #dddddd;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    font-weight:400;
}
.heatmap-title {
    font-weight:600;
}
.heatmap-resume__value {
    font-weight:600;
    font-size:15px;
}
.calendar-table__placeholder__button {
    width:40px;
    height:40px;
    float:right;
    clear:both;
    margin-bottom:5px;
}
.calendar-table__placeholder__button .vue-content-placeholders-img {
    height:100%;
}
.more-less {
    float: right;
    color: #212121;
}
.panel-title a {
    text-decoration:none;
    display:block;
}
.panel-title a i {
    border:4px solid transparent;
    margin-top:-4px;
    margin-right:-4px;
    border-radius:5px;
}
.panel-title a:hover i {
    border:4px solid #FF5722;
    background:#FF5722;
    color:#FFF;
}
.search-bar .search-bar-project .select2-selection.select2-selection--multiple .select2-search__field {
    padding-top:8px;
    padding-bottom:4px;
    margin-top:0;
}
.clickable {
    cursor: pointer;
}
.search-bar {
    width:100%;
}
.search-bar.fixed {
    position:fixed;
    top:0;
    padding:10px 15px 0 15px;
    z-index:10;
    background: #F5F5F5;
    box-shadow: 0px 1px 4px 0px #ccc;
}
.developer_list_avatar {
    width:50px;
    height:50px;
    border-radius:50%;
    background-position:center center;
    background-size:100%;
    display:inline-block;
    vertical-align:middle;
    margin-right:10px;
    border: 1px solid #DDD;
}

.text-down {
    color:red;
    font-weight:bold;
}
.text-up {
    color:#19C319;
    font-weight:bold;
}
.mt-4 {
    margin-top:20px;
}
.mt-2 {
    margin-top:10px;
}
[v-cloak] {
    display:none;
}
.lang-flag {
    width:16px;
}
.lang-dropdown .caret {
    color:#FFF;
}
.menu-item-lang {
    padding-top:24px;
}
.lang-dropdown .dropdown-menu {
    background: -moz-linear-gradient(top,#3c4b5b 3%,#3c4b5b 3%,#3c4b5b 23%,#3c465b 51%,#5e7993 100%,#223749 100%);
    background: -webkit-linear-gradient(top,#3c4b5b 3%,#3c4b5b 3%,#3c4b5b 23%,#3c465b 51%,#5e7993 100%,#223749 100%);
    background: linear-gradient(320deg,#070E18 0,#2A3B56 100%);
}
.menu-item-lang .open>a, 
.menu-item-lang .open>a:focus, 
.menu-item-lang .open>a:hover, 
.menu-item-lang .open>a:active {
    background:transparent;
}
.menu-item-lang .lang-dropdown li a {
    padding-top:5px !important;
    font-size: inherit !important;
    margin:0 !important;
    color:#FFF;
}
.menu-item-lang .dropdown-menu>li>a:focus, .menu-item-lang .dropdown-menu>li>a:hover {
    background:rgba(0, 0, 0, 0.9);
}
.btn-check {
    background:#999;
    color:#FFF;
    border-radius:50%;
    border:0;
    width:30px;
    height:30px;
    line-height:30px;
}
.btn-check-enabled, .btn-check:hover {
    background:green;
}
.btn-check-remove {
    background:red !important;
}
.block-center, .block-center > div {
    margin:0 auto;
}
.custom-hours {
    color:#0052CC;
    font-weight:bold;
}
.rounded-image-container {
    overflow:hidden;
    display:inline-block;
    width:32px;
    height:32px;
    border-radius:50%;
    text-align:center;
    vertical-align: middle;
    background-size:contain;
    background-position:center center;
}
.btn-dark, .btn-dark:active, .btn-dark:visited {
    background:#CC131A;
    color:#FFF;
}
.estimate {
    color:#0052CC;
}
.btn-special {
    background: #CC131a;
    border-color: #af090f;
    color:#FFF;
}
.btn-special:hover {
    color:#FFF;
}
.emoji {
    font-family: apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol;
}
.select2-user-pic {
    height:15px;
    width:15px;
    border-radius:50%;
    background-position: center center;
    display:inline-block;
    background-size: contain;
    vertical-align: middle;
}
.select2-user-name {
    vertical-align: middle;
    display:inline-block;
    font-weight: 600;
}

.report-rollup-data {
    width: 100%;
    border: 1px solid #e4e9ef;
    flex-wrap: wrap;
    display: flex;
    list-style: none;
    padding-left: 0;
}

.report-rollup-data li:nth-child(odd) {
    border-right: 1px solid #e4e9ef;
}

.report-rollup-data li {
    list-style: none;
    flex: 1;
    width: auto;
    padding: 0 20px;
    margin: 15px 0;
    font-size: 26px;
    color: #000;
    font-weight: bold;
}

.report-rollup-data li:last-child {
    border-left: 1px solid #e4e9ef;
}

.report-rollup-label {
    color: #79899d;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
}

.report-rollup-value .status {
    font-size:22px;
}

.report-rollup-value .weak-value {
    color: #CCC;
}
.report-rollup-value .small-value {
    font-size:14px;
}
.sidebar-nav-dark li {
    border-bottom: solid 1px #35414e;
}

.btn-primary:hover, .open > .dropdown-toggle.btn-primary {
    color: #FFF;
    background-color: #CB1517;
    border-color: #204D74;
}

.menu-toggle {
    color: #252E38;
}

    .menu-toggle i, .menu-toggle div {
        color: #000;
    }

.panel-default, canvas {
    border: 1px solid;
    border-color: #F1F2F3 #dfe0e4 #d0d1d5;
    border-radius: 12px;
}

.sidebar-nav-dark li > a {
    color: rgba(255,255,255,0.6);
}

.sidebar-nav-dark li:hover > a {
    white-space: nowrap;
    overflow: hidden;
}

.links-container .block {
    display: block;
    padding: 20px;
    font-size: 1em;
    color: #000;
    text-decoration: none;
    text-align: center;
}

.btn-default {
    background-color: #cb1906;
    border-color: #cb1906;
    color: #FFF;
    font-weight: 400;
    /* text-overflow: ellipsis; */
    position: relative;
    line-height: 16px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    text-decoration: none;
}

    .btn-default:hover {
        background-color: #C00000;
        border-color: #C00000;
    }

.btn {
    font-size: 12px;
}
.form-control {
    font-size: 13px;
}

.breadcrumb {
    font-size: 20px;
}

table.dataTable tfoot th, table.dataTable thead th {
    font-weight: 600;
}

.dropdown-menu {
    min-width: 140px;
    font-size: 12px;
}

.panel-heading {
    background: #F9F9F9 !important;
}


media (min-width: 768px)
#left-sidebar-wrapper .sidebar-nav li {
    text-indent: 12px;
    line-height: 30px;
}

.sidebar-nav li {
    text-indent: 8px;
    font-size: 12px;
    line-height: 34px;
}

style attribute {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.ranges li {
    font-size: 12px;
}

#left-sidebar-wrapper, .sidebar-nav-dark {
    background: #3c4b5b; /* Old browsers */
    background: -moz-linear-gradient(top, #3c4b5b 3%, #3c4b5b 3%, #3c4b5b 23%, #3c465b 51%, #5e7993 100%, #223749 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #3c4b5b 3%,#3c4b5b 3%,#3c4b5b 23%,#3c465b 51%,#5e7993 100%,#223749 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(320deg, #070E18 0, #2A3B56 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c4b5b', endColorstr='#223749',GradientType=0 ); /* IE6-9 */
}

.navbar, .navbar-collapse {
    background: #CB1417;
}

.navbar-brand > img {
    display: none;
}

.sidebar-nav-dark li {
    border: 0;
}

    .sidebar-nav-dark li div.nav-footer {
        background: linear-gradient(320deg, #070E18 0, #2A3B56 100%);
        text-align: right;
        border: 0;
        padding: 10px;
    }

.nav-footer img {
    width: 60%;
}

.btn-success {
    background-color: #82B91E !important;
}

.nav-tabs.nav-justified > li > a:hover {
    background-color: #3C4B5C;
}

.nav-tabs.nav-justified > li > a {
    border: none;
    border-radius: 0;
    color: #FFF;
    background-color: #546B87;
}

.active-clients, .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:hover {
    background-color: #CB1906;
}

table.dataTable thead > tr > th, table.invoice-table thead > tr > th {
    background-color: #EBEEF4 !important;
    color: #333;
}

.table-striped > tbody > tr:nth-child(odd) {
    background-color: #EFF4F7;
}

table.data-table tr {
    border-bottom: 1px solid #E0E7EF;
    border-top: 1px solid #E0E7F0;
}

tbody td {
    border-left: 1px solid #E0E7F0;
}

.btn-primary {
    color: #FFF;
    background-color: #82B91E;
    border-color: #7EAF08;
}

    .btn-primary:hover, .open > .dropdown-toggle.btn-primary {
        color: #FFF;
        background-color: #96CC32 !important;
        border-color: #93CA2F !important;
    }

table.dataTable thead > tr > th, table.invoice-table thead > tr > th {
    background-color: #6F8796 !important;
    color: #FFF;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: rgba(255,255,255,.9);
    border-color: #3B4A5B;
    color: #CB1906;
}

.nav-footer img {
    width: 75%;
}

.sidebar-nav-dark li div.nav-footer {
    padding-bottom: 25px;
    padding-top: 15px;
}

.navbar-default .navbar-brand {
    color: #ECF0F1;
}

.navbar, .navbar-collapse {
    background: #FFF !important;
}

thead th {
    border-left: 0;
}

.dashboard .panel-heading, .panel-heading {
    border-radius: 10px 10px 0 0;
}

.label-done {
    background-color: green;
}

.label-created {
    background-color: #DFE1E6;
    color: #172B4D;
}

.label-progress {
    background-color: #0052CC;
}
.label-alpha {
    background-color: #9fb39f;
}
.label-beta {
    background-color: #86b586;
}

.text-yes {
    color: green;
    font-weight: bold;
}

.text-no {
    color: #CB1906;
    font-weight: bold;
}

.table-total td {
    background: #999;
    color: #FFF;
    font-weight: bold;
}

.task_activities__datatable_row_notask, .tasks__datatable_row_notask {
    background: #FEE !important;
}

.report-rollup-data {
    width: 100%;
    border: 1px solid #e4e9ef;
    flex-wrap: wrap;
    display: flex;
    list-style: none;
    padding-left: 0;
}

.report-rollup-data li:nth-child(odd) {
    border-right: 1px solid #e4e9ef;
}

.report-rollup-data li {
    list-style: none;
    flex: 1;
    width: auto;
    padding: 0 20px;
    margin: 15px 0;
    font-size: 26px;
    color: #000;
    font-weight: bold;
}

.report-rollup-data li:last-child {
    border-left: 1px solid #e4e9ef;
}

.report-rollup-label {
    color: #79899d;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
}

.task-rate-table {
    margin-top: 20px;
    margin-bottom: 20px;
}

.table-load-more {
    background: #FFF;
    text-align: center;
    font-size: 16px;
    padding: 20px;
    display: block;
}

.mt-4 {
    margin-top: 4em;
}

/* Transactions Import System CSS */
.transaction-import-container {
}

.transaction-import-container__bigtext, .transaction-import-container__bigtext-center {
    font-size: 2.1em;
    line-height: 2.5em;
}

.transaction-import-container__bigtext-center {
    text-align: center;
}

.transaction-import-container__button {
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 1.5em;
}

.transaction-import-container__button--accept {
    background: green;
    color: #FFF;
}

    .transaction-import-container__button--accept:hover {
        color: #FFF;
        text-decoration: none;
    }

.transaction-import-container__button--continue {
    background: #204D74;
    color: #FFF;
}

    .transaction-import-container__button--continue:hover {
        color: #FFF;
        text-decoration: none;
    }

.transaction-import-container__button--reject {
    background: red;
    color: #FFF;
}

    .transaction-import-container__button--reject:hover {
        color: #FFF;
        text-decoration: none;
    }

.transaction-import-container__flow {
    counter-reset: step;
}

.transaction-import-container__flow__item {
    list-style: none;
    width: 25%;
    float: left;
    font-weight: bold;
    font-size: 0.9em;
    padding: 10px;
    color: #333;
    position: relative;
    text-transform: uppercase;
    text-align: center;
}

    .transaction-import-container__flow__item:before {
        width: 30px;
        height: 30px;
        content: counter(step);
        counter-increment: step;
        line-height: 27px;
        border: 2px solid #7d7d7d;
        display: block;
        text-align: center;
        margin: -10px auto 10px auto;
        border-radius: 50%;
        background-color: white;
    }

    .transaction-import-container__flow__item:after {
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #7d7d7d;
        top: 15px;
        left: -50%;
        z-index: -1;
    }

    .transaction-import-container__flow__item:first-child:after {
        content: none;
    }

.transaction-import-container__flow__item--active {
    color: green;
}

    .transaction-import-container__flow__item--active:before {
        border-color: green;
    }

    .transaction-import-container__flow__item--active:after + li:after {
        background-color: green;
    }

.transaction-import-container__upload {
    margin-top: 50px;
    margin-bottom: 50px;
    clear: both;
}

    .transaction-import-container__upload input {
        display: none;
    }

    .transaction-import-container__upload label {
        display: block;
        padding: 10px;
        color: #666;
        border: 1px solid #CCC;
        font-weight: normal;
    }

.clear {
    clear: both;
}

.transaction-import-container__analyse__legends {
    display: flex;
    margin-top: 30px;
}

.transaction-import-container__analyse__legends__item {
    flex: 1;
}

.transaction-import-container__analyse__legends__item__figure {
    width: 36px;
    height: 36px;
    float: left;
    background: #FFF;
    font-size: 24px;
    border: 1px solid #333;
    text-align: center;
    margin-right: 10px;
}

.bg-incoming {
    background: green;
}

.bg-outcoming {
    background: red;
}

.color-incoming {
    color: green;
}

.color-outcoming {
    color: red;
}

.transaction-import-container__analyse__legends__item__text {
    margin-top: 10px;
}

.transaction-import-container__analyse__data {
    margin-top: 50px;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
    margin-top: 20px;
}

.transaction-import-container__analyse__data__table {
    width: 100%;
}

    .transaction-import-container__analyse__data__table tbody {
    }

    .transaction-import-container__analyse__data__table tr th {
        background: #333;
        color: #FFF;
        text-align: center;
        font-weight: bold;
        padding: 10px;
    }

    .transaction-import-container__analyse__data__table tr {
        border-bottom: 1px solid #CCC;
    }

.transaction-import-container__analyse__data__table__header-select {
    background: #CCC;
}

    .transaction-import-container__analyse__data__table__header-select td {
        padding: 10px;
        background: #CCC;
    }

        .transaction-import-container__analyse__data__table__header-select td select {
            width: 100%;
            padding: 5px;
            font-size: 1.2em;
        }

.transaction-import-container__analyse__data__table tr:nth-child(2n) {
    background: #F3F3F3;
}

.transaction-import-container__analyse__data__table tr td {
    padding: 10px;
}

.list-filter-element {
    display: inline-block;
    position: relative;
}

    .list-filter-element .combobox-container {
        display: inline-block;
        vertical-align: middle;
        max-width: 200px;
        margin-bottom: 0;
    }

.list-filter-form-control {
    display: inline-block;
    vertical-align: middle;
}

#statement {
    display: none;
}

.statement-year-title {
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
}

.statement .col-md-3 {
    border: 1px solid #CCC;
}

.statement-month-title {
    display: block;
    text-align: center;
    font-weight: bold;
}

.statement-month-content {
    text-align: center;
    display: block;
    font-size: 3em;
}

.bank-transaction-details-suggestions-block {
    background: #FFF;
    border: 1px solid #CCC;
    margin-bottom: 30px;
    padding: 10px;
}

.bank-transaction-details-label {
    color:#CCC;
    display:block;
    white-space: nowrap;
}

.bank-transaction-details-value {
    color:#333;
    display:block;
}

.bank-transaction-details-table-api tr {
    border:0 !important;
}

.footer__version {
    text-align: right;
    font-size: 0.8em;
    color: #999;
}

.text-small {
    font-size: 0.8em;
}

.table-striped > tbody > tr:nth-child(odd) {
    background-color: #f9f9f9 !important;
}

a {
    color: #0052cc;
    text-decoration: none;
}

.nav-tabs.nav-justified > li > a {
    border: none;
    border-radius: 0;
    font-weight: 500;
    color: #333;
    background-color: #eee;
}

.nav-tabs.nav-justified > li:first-child > a {
    border-radius: 3px 0 0 3px;
    border-left: none;
}

.nav-tabs.nav-justified > li:last-child > a {
    border-radius: 0 3px 3px 0;
}

.nav-tabs.nav-justified > li > a:hover {
    background-color: #cb1906;
    color: #fff;
}

.table-total td {
    background: #EEEEEE;
    color: #333;
    font-weight: 800;
}

tbody td {
    border-left: 0;
}

table.data-table tr {
    border-bottom: 1px solid #E9E9E9;
    border-top: 1px solid #E9E9E9;
}

table.dataTable thead > tr > th, table.invoice-table thead > tr > th, table.table-striped thead > tr > th {
    background-color: #F5F5F5 !important;
    color: #333;
}

.btn-primary {
    color: #FFF;
    background-color: #008000;
    border-color: #008000;
}

.bank-transaction-invoice-details {
    font-size: 0.9em;
}

.small-value {
    font-weight: bold;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

    .slider.round:before {
        border-radius: 50%;
    }

.switch-label {
    font-weight: 500;
    display: block;
}

.multiselect__tag {
    background-color: #EEEEEE !important;
    border: 1px solid #aaa !important;
    border-radius: 4px !important;
    color: #333 !important;
}

.multiselect__option--highlight {
    background: #5897FB !important;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a !important;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: transparent !important;
    color: #333 !important;
}

    .multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {
        color: #333 !important;
    }

@media (min-width: 768px) {
    .task-video-modal .modal-dialog {
        width: 60%;
    }
}

.task-video-modal .vjs-portrait {
    height: 70vh !important;
}

.task-video-modal .modal-header, .modal-header {
    border-radius: 4px;
    background-color: #FFF;
    color: #333;
}

.task-video-modal .modal-footer-title, .modal-footer-title {
    display: block;
    color: #4A86E8;
    font-size: 1.2em;
}

.task-video-modal .modal-footer-copy {
    color: #B7B7B7;
}

.task-video-modal .close {
    color: #333;
    margin-top: -20px;
}

.command-textbox {
    width: 280px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-right: 12px;
}

.filter-table-field {
    width: 180px;
    margin-right: 17px;
    background-color: white !important;
}

@media (min-width: 768px) {
    #left-sidebar-wrapper::-webkit-scrollbar {
        display: none;
    }

    #left-sidebar-wrapper {
        scrollbar-width: none;
    }

        #left-sidebar-wrapper ul {
            min-height: 1100px;
        }
}

@media (min-width: 768px) {
    @media (max-height: 744px) {
        div.nav-footer {
            min-width: 250px !important;
        }
    }
}

@media (max-width: 768px) {
    .alert {
        overflow-wrap: break-word;
    }

    .command-textbox {
        width: 96%;
    }

    .command-wrapper {
        padding: 10px;
    }

    .filter-table-field {
        width: 100%;
        margin-bottom: 10px;
    }

    #top_right_buttons {
        margin-bottom: 10px;
    }

    .vendors-show-action-buttons {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #wrapper {
        padding-top: 70px;
    }

    .navbar-fixed-top, .navbar-collapse {
        background: #3c4b5b; /* Old browsers */
        background: -moz-linear-gradient(top, #3c4b5b 3%, #3c4b5b 3%, #3c4b5b 23%, #3c465b 51%, #5e7993 100%, #223749 100%) !important; /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #3c4b5b 3%,#3c4b5b 3%,#3c4b5b 23%,#3c465b 51%,#5e7993 100%,#223749 100%) !important; /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(320deg, #070E18 0, #2A3B56 100%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c4b5b', endColorstr='#223749',GradientType=0 ); /* IE6-9 */
        border-bottom: 1px solid #252E38;
    }
}

.navbar-wrapper {
    overflow-x: hidden;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #cb1906;
    border-color: #cb1906;
}

.navbar-default .navbar-toggle {
    border-color: #FFF;
}

    .navbar-default .navbar-toggle .icon-bar {
        background-color: #FFF;
    }

    .navbar-default .navbar-toggle:focus .icon-bar, .navbar-default .navbar-toggle:hover .icon-bar {
        background-color: #FFF;
    }

.attachment-block {
    border: 1px solid #CCC;
    background: #FFF;
    margin-bottom: 20px;
}

.attachment-block__head {
    height: 200px;
    overflow: hidden;
}

.attachment-block__body {
    padding: 10px;
}

.attachment-name {
    height: 20px;
    overflow: hidden;
}

body {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Helvetica Neue','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Meiryo UI',Arial,sans-serif;
    font-size: 12px;
    line-height: 1.42857143;
    color: #333;
}

.h3, h3 {
    font-size: 16px;
}

.report-rollup-data li {
    font-size: 16px;
    font-weight: 700;
}

.report-rollup-label {
    font-size: 10px;
 
}
.modal-backdrop {
    position:fixed;
}
.modal-close {
    position:absolute;
    top:5px;
    right:5px;
    color:#333;
    font-weight: normal;
    font-size:15px;
    background:transparent;
    border:0;
}
#global_reportrange {
    background: rgb(255, 255, 255);
    cursor: pointer;
    padding: 10px 14px;
    border: 1px solid rgb(204, 204, 204);
    margin-top: 0px;
    border-radius: 4px;
}